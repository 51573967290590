import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'
import imgCaGov from '../../assets/ca-gov.png';
import accessibility from '../../assets/policy/Accessibility-Policy-HFC.pdf';

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>How to reach us</b>
              <ul>
                <li><a href="tel:+19057602224" className="footer-link"><FaPhoneVolume /> (+1) 905 760 2224</a></li>
                <li><a href="mailto:cac@hansa-flex.com" className="footer-link"><MdOutlineEmail /> cac@hansa-flex.com</a></li>
                <li><a href="https://maps.app.goo.gl/F5TxKgowgsSHnFkA7" className="footer-link"><MdLocationOn /> Concord, Ontario, L4K 5R2</a></li>
              </ul>
              <br />

              <img src={imgCaGov} alt="Location Image" style={{ width: '200px', height: '60px', boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }} />
              <br /><br /><br />
            </div>

          </Col>

          <Col sm={12} lg={4}>

            <div className='footer-column-div'>
              <b>About HANSA‑FLEX</b>
              <ul>
                <li><a href="https://www.hansa-flex.de/en/magazine/" className="footer-link">Hydraulic Press</a></li>
                <li><Link to="/certificates" className="footer-link">ISO 9001:2015 Certificate</Link></li>
                <li><a href={accessibility} className="footer-link">Accessibility</a></li>
                <li><Link to="/compliance" className="footer-link">Compliance</Link></li>
                {/* <li><Link to="/DiligenceAct" className="footer-link">Diligence Act</Link></li> */}



                {/* Uncomment the line below if needed */}
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
              <br />
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>Popular Categories</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">Products</Link></li>
                <a href="https://my.hansa-flex.ca/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a>


              </ul>
              <br />
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <b>Follow us</b>

          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col className="circle-col"><div><img src={Imgkaggle} alt="" /></div></Col>*/}
          <Col className="circle-col"><div><a href="https://www.kununu.com/de/hansa-flex" target="_blank" rel="noopener noreferrer"><img src={Imgkaggle} alt="" /></a></div></Col>
          {/*<Col className="circle-col"><div><img src={Imgxing} alt="" /></div></Col>*/}
          <Col className="circle-col"><div><a href="https://www.xing.com/pages/hansa-flexag" target="_blank" rel="noopener noreferrer"><img src={Imgxing} alt="" /></a></div></Col>
          <Col className="circle-col"><div><a href="https://www.linkedin.com/company/hansa-flex-ag" target="_blank" rel="noopener noreferrer"><img src={Imglinkidin} alt="" /></a></div></Col>
          <Col className="circle-col"><div><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><img src={Imgyoutube} alt="" /></a></div></Col>
          <Col className="circle-col"><div><a href="https://de-de.facebook.com/hansaflexag" target="_blank" rel="noopener noreferrer"><img src={Imgfacebook} alt="" /></a></div></Col>

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Copyright All Rights Reserved</a><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">Privacy Policy</Link>


          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
