import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgTree from '../../assets/news-events/tree2.jpg';

function TreePlantation() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <ImageDescription
                imageSrc={imgTree}
                title={"Tree plantation on Environment Day !"}
                paragraphs={[
                    "On World Environment Day, HANSA-FLEX India Pvt Ltd organized a tree plantation drive with a focus on environmental conservation. The team carefully selected suitable sites for planting trees. A systematic process was followed to ensure proper tree placement and growth. Employees and volunteers worked together to plant trees. The spirit of collaboration contributed to the success of the initiative. The tree plantation drive aligned with the company’s Corporate Social Responsibility goals. It demonstrated their commitment to sustainability."
                    , "HANSA-FLEX India’s commitment to environmental stewardship is commendable, and such initiatives pave the way for a more sustainable world"

                ]}

            />
        </div>
    );
}

export default TreePlantation;