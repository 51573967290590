import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './NumberGrid.css'; // Import the CSS file

const NumberGrid = ({ numbers, subtitles }) => {
    return (
        <Container className="number-grid-container"> {/* Add a class name to the container */}
            <Row className="justify-content-center">
                {numbers.map((number, index) => (
                    <Col key={index} className="text-center">
                        <div className="number">{number}</div> {/* Remove inline styles */}
                        <div className="subtitle">{subtitles[index]}</div> {/* Remove inline styles */}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default NumberGrid;

