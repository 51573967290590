import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FaMapMarkerAlt, FaPhone, FaLanguage, FaCode, FaShoppingCart } from 'react-icons/fa'; // Importing icons
import logo from '../../assets/logo.svg';
import './CustomNavbar.css';
import { Link } from 'react-router-dom';

function CustomNavbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Navbar className='white-bg margin-left' variant="light" expand="lg" expanded={expanded} >
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}

            className="d-inline-block align-top custom-nav-logo"
            alt="Logo"
          />
        </Navbar.Brand>

        <div className='custom-nav-menu'>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <div className='left-align-items'>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="c-nav-menu">
                {/* <Nav.Link href="#branch-finder">
                  <FaMapMarkerAlt /> branch-finder
                </Nav.Link> */}
                <Nav.Link href="#contact-us-section">
                  <FaPhone /> Contact us
                </Nav.Link>
                {/* <Nav.Link href="#language">
                  <FaLanguage /> Language
                </Nav.Link> */}
                <Nav.Link href="https://shop.hansa-flex.ca/en_GB/" target="_blank" rel="noopener noreferrer">
                  <FaShoppingCart />  Online-Catalogue

                </Nav.Link>
                <Nav.Link href="https://my.hansa-flex.ca/en_GB/login" target="_blank" rel="noopener noreferrer">
                  <FaCode />  X-CODE Manager

                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>

        </div>

      </Navbar>
    </div>
  );
}

export default CustomNavbar;
