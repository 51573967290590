import React, { useEffect } from 'react';
import pdfForcedLabourAct from '../assets/FightingAgainstForcedLabourAndChildLabourInSupplyChainAct.pdf';
import FullTextButton from '../Components/FullTextButton/FullTextButton';
import SectionHeader from '../Components/SectionHeader/SectionHeader';


function Compliance() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>

            <SectionHeader title={"Compliance"} /> <br /><br /><br />

            <FullTextButton title={"German Supply Chain Due Diligence Act"} buttonLink={"https://www.hansa-flex.de/en/complaints-procedure-8-lksg/"} paragraph={"It contains the regulations on how HANSA‑FLEX AG intends to contribute to respect for human rights and the environment in its own company and along the supply chain."} buttonText={"Read full German Supply Chain Due Diligence Act"} />
            <br /><br />

            <FullTextButton title={"Fighting Against Forced Labour"} buttonLink={pdfForcedLabourAct} paragraph={"As an international company, represented in 40 countries actos the world, we are highly aware of our role and responsibilities. We aim to promote responsible business practices, with support and respect to the protection of human rights, both within our company and when dealing with business partners throughout the supply chain. We do not tolerate forced labour or child labour: We are committed to the effective principles as demonstrated strongly in our Code of Conduct."} buttonText={" Read full Fighting against forced labour and Child Labour in Supply Chain Act (Canada)"} />

        </div>

    );
}

export default Compliance;