import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgBauma3 from '../../assets/news-events/Excon2.jpg';
import imgBauma2 from '../../assets/news-events/Bauma2.jpg';

function Bauma() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <ImageDescription
                imageSrc={imgBauma2}
                title={"Bauma Conexpo India 2023"}
                paragraphs={["The 6th edition of international trade fair Bauma Contempo India 2023, construction machinery event was held in Greater Noida, from Jan 31st -3rd Feb 2023. This event saw the participation of more than 60,000 visitors from all over the world. HANSA-FLEX India Pvt Ltd. was part of this world expo event and had an impressive more than 400 visitors at their booth. "
                    , "The main attraction of the event was the presence of all suppliers and vendors under one roof. International visitors from the HANSA-FLEX group also attended the event, greatly adding to the success of the event. "

                ]}

                alignment={'center'}
            />

            <ImageDescription
                imageSrc={imgBauma3}
                paragraphs={[
                    "They were able to meet and network with the various suppliers and vendors to gain valuable insights into their respective industries."
                    , "Bauma India was a great success and HANSA-FLEX India Pt. Ltd. was delighted to be a part of it."
                ]}
                alignment={'center'}
            />
        </div>
    );
}

export default Bauma;