import React, { useEffect } from 'react';
import './Promoter.css';
import { BsCheckLg } from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Promoter = ({ title, checklistItems, btnLabel1, btnLabel2, btnLabel1Link, btnLabel2Link, imageUrl }) => {
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 400, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);


  return (<div>
    {isMobile ? <></> : <><div className="full-window" >
      <div className="centered-div">
        <div className="centered-div-items" >
          <h1 className='centered-div-title'>{title}</h1>
          <ul className="checklist">
            {checklistItems.map((item, index) => (
              <li key={index}><BsCheckLg className='li-promoter' /> {item}</li>
            ))}
          </ul>
          <div className="button-container">
            {btnLabel1 && btnLabel1Link && <a href={btnLabel1Link} className="btn-pink">{btnLabel1}</a>}
            {btnLabel2 && btnLabel2Link && <a href={btnLabel2Link} className="btn-pink">{btnLabel2}</a>}
          </div>
        </div>
        <img src={imageUrl} className="Karton-card-image" alt="Hansa-Flex Kartons Shop" data-aos="fade-down"></img>
      </div>
    </div></>}

  </div>

  );
};

export default Promoter;
